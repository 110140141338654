import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const AntiWrinkles = () => {

  useDocumentTitle('AntiWrinkles - Aesthetics Ocean');

    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesDermalFiller = [
        { name: "1 Area", price: "£200" },
        { name: "2 Area", price: "£250" },
        { name: "3 Area", price: "£300" },
    
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/AW1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/AW2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/AW3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DO ANTI-WRINKLE TREATMENTS COST?",
          answer: `During your consultation, we will discuss your specific goals and areas of concern. Based on your needs, we will recommend the appropriate treatment. The price varies depending on the treatment area and the specific procedure used. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "I'M PREGNANT, CAN I HAVE ANTI-WRINKLE TREATMENTS?",
          answer: ` Currently, there is no comprehensive research on the safety of anti-wrinkle treatments during pregnancy. Therefore, we do not recommend these treatments for pregnant women. Your safety is our priority.`,
        },
        {
          question: "HOW LONG WILL THE RESULTS LAST?",
          answer: ` Anti-wrinkle treatment results typically last between 3 to 6 months, depending on the area treated, the type of treatment used, and individual factors. We’ll provide you with detailed information during your consultation to help you understand what to expect.`,
        },
      ];

  return (
  
  <>
  <Header/>
  <WhatsappButton/>
   
    <DynamicBanner title="AntiWrinkles" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Anti Wrinkles"
      description="Anti-wrinkle treatments are designed to reduce the appearance of fine lines and wrinkles, restoring a smoother and more youthful complexion. Popular options include topical products, injections, and laser therapies that target skin's elasticity and texture.."      offer="Limited Time Offer!"
      description2="Specializing in anti-aging and natural enhancement. Unsure of which treatment would suit you? Book in for a complimentary facial assessment and advice with one of our experts."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/Anti Wrinkle.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Anti-wrinkle Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How Anti-Wrinkle Treatments Work?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Anti-wrinkle treatments can target facial lines, crow's feet, forehead creases, and other common signs of aging, while effectively addressing skin laxity and loss of elasticity. Our clinic uses premium, CE-marked products that are safe, effective, and long-lasting. Suitable for men and women of all ages, these treatments offer a non-surgical solution to improving your appearance and achieving natural-looking results.</p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All anti-wrinkle treatments are carried out by our in-house Cosmetic Doctor.
        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Anti-Wrinkle"
          serviceDescription="Smooth out fine lines and wrinkles for a naturally refreshed look."
          services={servicesDermalFiller}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default AntiWrinkles;
