import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsappButton = () => {
  const handleButtonClick = () => {
    window.open('https://wa.me/447398165652', '_blank');
  };

  return (
    <div className="fixed bottom-4 right-4 md:bottom-6 md:right-6 z-50">
      <button
        className="flex items-center bg-green-500 text-white font-semibold py-2 px-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
        onClick={handleButtonClick}
      >
        <FaWhatsapp className="mr-2 text-xl" />
        How can I help you?
      </button>
    </div>
  );
};

export default WhatsappButton;
