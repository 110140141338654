import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import DynamicBanner from "../Components/DynamicBanner";
import Price from "../Components/Price"; 
import WhatsappButton from '../Components/WhatsappButton';
import Appointment from '../Components/Appointment';
import useDocumentTitle from '../useDocumentTitle';

const Pricing = () => {

  useDocumentTitle('Pricelist - Aesthetics Ocean');

  const breadcrumbs = [
    { label: 'Price List', href: '/' },
    { label: 'Pricing', href: '/Pricing' }
  ];

  // Data for various services
  const servicesDermalFiller = [
    { name: "Lip 1ML", price: "£300" },
    { name: "Nasolabial", price: "£300" },

  ];

  const servicesMesotherapy = [
    { name: "1 Session", price: "£300" },
    { name: "4 Session", price: "£1000" },
  ];

  const servicesVitaminB12Injections = [
    { name: "Vit B12 Injection ", price: "£50" }
  ];

  const servicesMicroneedling = [
    { name: "Face and Neck", price: "£250" },
    { name: "Face and Neck (3 Courses)", price: "£650" },
    { name: "Full Face (3 Courses)", price: "£500" },
  ];

  const servicesRFFractional = [
    { name: "1 Session", price: "£550" },
    { name: "2 Sessions ", price: "£1000" }
  ];

  const servicesSkinBooster = [
    { name: "1 Session", price: "£300" },
    { name: "4 Sessions ", price: "£1000" }
  ];

  const servicesAntiWrinkle = [
    { name: "1 Area", price: "£200" },
    { name: "2 Area", price: "£250" },
    { name: "3 Area", price: "£300" },
  ];

  const servicesHairRestoration = [
    { name: "1 Session", price: "£400" },
    { name: "4 Sessions", price: "£1500" }
  ];

  const servicesHydrafacial= [
    { name: "HP(Antiagening) - 1 Session", price: "£199" },
    { name: "HP(Sun Damage & Pigmentation) - 1 Session", price: "£199" },
    { name: "HP(Clear Skin) - 1 Session", price: "£199" },
    { name: "HP(Signature) - 1 Session", price: "£250" },
    { },
    { name: "HP(Antiagening) - 6 Session", price: "£1170"  },
    { name: "HP(Sun Damage & Pigmentation) - 6 Session", price: "£1170"  },
    { name: "HP(Clear Skin) - 6 Session", price: "£1170"  },
    { name: "HP((Signature) - 6 Session", price: "£1450"  },
  ];

  const servicesPigmentation = [
    { name: "Cosmelan Peel", price: "£1500" }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <WhatsappButton/>
      <main className="flex-grow">
        <DynamicBanner title="Pricing" breadcrumbs={breadcrumbs} />
        <Price
          serviceName="Dermal Filler"
          serviceDescription="Enhance facial contours and volume, targeting areas like lips and nasolabial folds for a youthful appearance."
          services={servicesDermalFiller}
        />
        
        <Price
          serviceName="Anti-Wrinkle"
          serviceDescription="Smooth out fine lines and wrinkles for a naturally refreshed look."
          services={servicesAntiWrinkle}
        />

         <Price
          serviceName="Microneedling"
          serviceDescription="Stimulate collagen production to improve skin texture, tone, and elasticity."
          services={servicesMicroneedling}
        />

        <Price
          serviceName="Mesotherapy"
          serviceDescription="Revitalize your skin with nutrient-rich injections that hydrate, firm, and brighten."
          services={servicesMesotherapy}
        />

        <Price
          serviceName="RF Fractional"
          serviceDescription="Tighten skin and reduce wrinkles with advanced radiofrequency therapy."
          services={servicesRFFractional}
        />
         <Price
          serviceName="HydraFacial"
          serviceDescription="Cleanse, exfoliate, and hydrate your skin for a radiant complexion."
          services={servicesHydrafacial}
        />
     

        <Price
          serviceName="Skin Booster"
          serviceDescription="Hydrate and rejuvenate your skin for a radiant, youthful glow."
          services={ servicesSkinBooster}
        />

        <Price
          serviceName="Hair Restoration"
          serviceDescription="Encourage healthy hair growth and restore thinning hair."
          services={servicesHairRestoration}
        />

       <Price
          serviceName="Pigmentation Treatment"
          serviceDescription="Brighten and even out skin tone with our powerful pigmentation treatments."
          services={servicesPigmentation}
        />
     
     <Price
          serviceName="Vitamin B12 Injections"
          serviceDescription="Boost energy and vitality with our quick and effective Vitamin B12 shots."
          services={servicesVitaminB12Injections }
        />
     
     
     
      </main>
      <Appointment/>
      <Footer />
    </div>
  );
};

export default Pricing;
