import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const HairRestoration= () => {

  useDocumentTitle('HairRestoration - Aesthetics Ocean');

    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesHairRestoration = [
        { name: "1 Session", price: "£400" },
        { name: "4 Sessions", price: "£1500" }
      ];
    
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/HR2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/HR1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/HR3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DOES HAIR RESTORATION COST?",
          answer: `During your consultation, we will discuss your specific hair concerns and goals. Based on your needs, we will recommend the appropriate Hair Restoration treatment plan. The cost varies depending on the treatment area and the number of sessions required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "IS HAIR RESTORATION SUITABLE FOR BOTH MEN AND WOMEN?",
          answer: `Yes, Hair Restoration treatments are suitable for both men and women experiencing hair thinning or early-stage hair loss. Our treatments are tailored to address the unique needs of each individual, ensuring effective results.`,
        },
        {
          question: "HOW LONG WILL IT TAKE TO SEE RESULTS FROM HAIR RESTORATION?",
          answer: `Results from Hair Restoration treatments vary depending on the method used and individual factors. Generally, noticeable improvements can be seen within 3 to 6 months, with optimal results appearing after a full course of treatment. We’ll provide you with a personalized timeline during your consultation.`,
        },
      ];

  return (
  
  <>
  <Header/>
  <WhatsappButton/>
   
    <DynamicBanner title="Hair Restoration" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Hair Restoration"
      description="Hair Restoration is a popular non-surgical treatment designed to combat hair loss and promote hair regrowth. This treatment typically involves advanced techniques such as PRP (Platelet-Rich Plasma) therapy, which uses your body’s natural growth factors to stimulate hair follicles and encourage new hair growth."      offer="Limited Time Offer!"
      description2="Whether you're dealing with thinning hair or early-stage hair loss, our Hair Restoration treatments can help restore volume, thickness, and confidence. Book a consultation to find out how this treatment can be tailored to your needs."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/hair restoration.jpeg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Hair Restoration Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How Hair Restoration Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Hair Restoration treatments can effectively address thinning hair and early-stage hair loss by stimulating the hair follicles and encouraging new growth. Our advanced techniques, such as PRP (Platelet-Rich Plasma) therapy, use your body’s natural growth factors to rejuvenate and strengthen hair follicles, leading to thicker, healthier hair. Suitable for both men and women, these treatments offer a non-surgical solution to restoring your hair’s natural volume and confidence.    </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Hair Restoration treatments are administered with precision and care to ensure optimal results.


        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Hair Restoration"
          serviceDescription="Encourage healthy hair growth and restore thinning hair."
          services={ servicesHairRestoration}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default HairRestoration;
