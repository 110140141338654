import React from 'react';


const PageLayout= ({ title, description,description2, offer, price, oldPrice, phoneNumber, imageUrl }) => {
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];

  return (
  
  
   
    <div className="mx-auto px-4 pt-4 lg:px-20 flex flex-col lg:flex-row items-center lg:items-start bg-gray-100">
      {/* Image Section */}
      <div className="lg:w-1/2 flex justify-center lg:justify-start mb-8 lg:mb-0">
        <img
          src={imageUrl}
          alt={title}
          className="w-auto h-auto max-w-lg mb-4 shadow-md"
        />
      </div>

      {/* Text Section */}
      <div className="lg:w-1/2 lg:pl-8 text-gray-700">
        <h2 className="text-3xl font-bold mb-4 text-gray-900">
          {title}
        </h2>
        <p className="mb-4">
          {description}
        </p>
        <p className="mb-4">
          {description2}
        </p>
        
        <p className="mb-4">
          Call <a href={`tel:${phoneNumber}`} className="text-blue-600 underline">{phoneNumber}</a> for more information or to book an appointment.
        </p>
      </div>
    </div>

    
 
  );
};

export default PageLayout;
