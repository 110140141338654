const GoogleMap = () => {
    return (
      <section className="py-12">
        <div className="container mx-auto px-6 lg:px-20">
          <div className="w-full h-96">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48358.49821079931!2d0.488750118755847!3d51.38890471832292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8d2ecb5cb7687%3A0xaff4f0a1d00a6b0e!2sAura%20Aesthetics%20Clinic!5e0!3m2!1sen!2suk!4v1614081962345!5m2!1sen!2suk"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </section>
    );
  };
  
  export default GoogleMap;
  