import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';



const PigmentationTreatment= () => {

  useDocumentTitle('Pigmentation - Aesthetics Ocean');

    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesPigmentation = [
        { name: "Cosmelan Peel", price: "£1500" }
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/p2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/p1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/p3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DO PIGMENTATION TREATMENTS COST?",
          answer: `During your consultation, we will discuss your specific skin concerns and goals. Based on your needs, we will recommend the appropriate pigmentation treatment. The cost varies depending on the treatment method and the extent of the area being treated. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "CAN PIGMENTATION TREATMENTS BE DONE ON ALL SKIN TYPES?",
          answer: `Yes, our pigmentation treatments are safe and effective for all skin types. We tailor the treatment to your skin's specific needs, ensuring the best possible outcome while minimizing any risk of side effects.`,
        },
        {
          question: "HOW LONG WILL IT TAKE TO SEE RESULTS FROM PIGMENTATION TREATMENTS?",
          answer: `Results from pigmentation treatments can vary based on the type of treatment and individual skin response. Generally, improvements can be seen after a few sessions, with optimal results becoming more evident over time. We’ll provide a personalized treatment plan and timeline during your consultation.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Pigmentation Treatment" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Pigmentation Treatment"
      description="Pigmentation treatments are a popular non-surgical option designed to address uneven skin tone, dark spots, and hyperpigmentation. These treatments work by targeting the melanin in the skin, helping to lighten and even out the complexion. Common methods include laser therapy, chemical peels, and topical treatments, each tailored to your specific skin concerns."      offer="Limited Time Offer!"
      description2="Specializing in skin brightening and tone correction. Unsure of which treatment would suit you? Book a complimentary consultation to discuss your skin concerns and receive personalized advice."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Treatments/p-01.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Pigmentation Treatment Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How Pigmentation Treatment Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Pigmentation treatments effectively target uneven skin tone, dark spots, and hyperpigmentation by addressing the excess melanin in the skin. At our clinic, we use advanced techniques such as laser therapy, chemical peels, and specialized topical treatments that are safe, effective, and tailored to your specific needs. These treatments are suitable for all skin types and offer a non-surgical solution to achieving a more even, radiant complexion.   </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Pigmentation treatments are administered with the utmost care to ensure optimal results and a brighter, more balanced skin tone.  </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Pigmentation Treatment"
          serviceDescription="Brighten and even out skin tone with our powerful pigmentation treatments."
          services={servicesPigmentation}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default PigmentationTreatment;
