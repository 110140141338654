import React from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrendingTreatmentsSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      };

  const treatments = [
    { img: `${process.env.PUBLIC_URL}/assets/Images/Microneedling.jpg`, title: 'Microneedling' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/hair restoration.jpeg`, title: 'Hair Restoration' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Dermal Fillers.jpg`, title: 'Dermal Fillers' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Mesotherapy.jpg`, title: 'Mesotherapy' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Injections.jpg`, title: 'Vitamin B12 Injection' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/RF Fractional.jpg`, title: 'RF Fractional' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Skin Booster.jpeg`, title: 'Skin Booster' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/RF Fractional.jpg`, title: 'RF Fractional' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Anti Wrinkle.jpg`, title: 'Anti Wrinkle ' },
    { img: `${process.env.PUBLIC_URL}/assets/Images/Pigmentation.jpg`, title: 'Pigmentation ' }
  ];

  return (
    <section className="bg-white py-10 px-5 lg:px-20 text-center">
      <h3 className="text-xs font-bold text-[#c16240] mb-2">NON SURGICAL <span className="text-gray-800">TREATMENTS</span></h3>
      <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Trending Treatments</h2>
      <Slider {...settings}>
        {treatments.map((treatment, index) => (
          <div key={index} className="px-3" >
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <img src={treatment.img} alt={treatment.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
              <h3 className="text-lg font-semibold">{treatment.title}</h3>
        
            </div>
          </div>
        ))}
      </Slider>
      <br/>
      <button className=" border border-[#c16240] text-[#c16240] py-2 px-6  hover:bg-[#c16240] hover:text-white transition duration-300">
      <Link to="/treatments"> See More</Link>
        </button>
    </section>
  );
};

export default TrendingTreatmentsSlider;
