import React from 'react';
import BookAppointment from './BookAppointment';

const Appointment = () => {
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

  return (
    <div
      className="relative flex items-center justify-center h-80 md:h-auto bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Images/bg.jpg)` }}
    >
      {/* White Overlay */}
      <div className="absolute inset-0 bg-white bg-opacity-80"></div>

       {/* Content */}
       <div className="relative z-10 flex flex-col items-center text-center p-2 md:p-6 rounded-lg max-w-2xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-semibold mb-2 text-gray-800">Book An Appointment</h2>
        <p className="text-md md:text-lg mb-3 text-gray-700">
        Call us to schedule an appointment and find out more about our services, or click on the button below to book directly.   </p>
        <BookAppointment onClick={handleButtonClick}>Book Appointment</BookAppointment>
      </div>
    </div>
  );
};

export default Appointment;
