import React, { useState } from "react";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import emailjs from "emailjs-com";
import Modal from "react-modal";

emailjs.init("BtV8_OZXSsIVXVfHg");

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '0px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const ContactFormWithInfo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    acceptedTerms: false,
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.acceptedTerms) {
      emailjs
        .send(
          "service_0s58dsc",
          "template_sj2tfvl",
          formData,
          "BtV8_OZXSsIVXVfHg"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setModalIsOpen(true);
          },
          (err) => {
            console.error("FAILED...", err);
          }
        );
    } else {
      alert("Please accept the terms and conditions.");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    window.location.reload();  // Reload the page after closing the modal
  };

  return (
    <section className="bg-gray-50 py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-20 flex flex-col lg:flex-row gap-8">
        {/* Form Section */}
        <div className="lg:w-2/3 w-full">
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">
            Make an Enquiry
          </h2>
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 shadow-lg rounded-lg"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-[#c16240]"
                  placeholder="Name"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-[#c16240]"
                  placeholder="Email"
                />
              </div>
              <div className="mb-4 col-span-1 sm:col-span-2">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-[#c16240]"
                  placeholder="Phone Number"
                />
              </div>
              <div className="mb-4 col-span-1 sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-medium mb-1"
                >
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-[#c16240]"
                  placeholder="Message"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="acceptedTerms"
                name="acceptedTerms"
                checked={formData.acceptedTerms}
                onChange={handleChange}
                required
                className="mr-2"
              />
              <label htmlFor="acceptedTerms" className="text-gray-700 text-sm">
                I have read and agree to Disclaimer & Privacy Policy.{" "}
                <span className="text-red-500">*</span>
              </label>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-[#c16240] text-white py-2 px-4 hover:bg-yellow-600 transition-colors duration-300"
              >
                Send
              </button>
            </div>
          </form>
        </div>

        {/* Contact Info Section */}
        <div className="lg:w-1/3 w-full flex flex-col space-y-6 justify-start mt-20">
          <div className="bg-gray-70 flex items-center p-6 rounded-lg shadow-lg h-30">
            <FaEnvelope className="text-3xl text-gray-700 mr-4" />
            <div>
              <h4 className="text-lg font-medium">Email Us</h4>
              <p className="text-gray-700">hello@auraaestheticsclinic.com</p>
              <br />
            </div>
          </div>
          <div className="bg-gray-70 flex items-center p-6 rounded-lg shadow-lg h-30">
            <FaPhoneAlt className="text-3xl text-gray-700 mr-4" />
            <div>
              <h4 className="text-lg font-medium">Call Us</h4>
              <p className="text-gray-700">01634 562 865</p>
              <br />
            </div>
          </div>
          <div className="bg-gray-70 flex items-center p-6 rounded-lg shadow-lg h-30">
            <FaMapMarkerAlt className="text-3xl text-gray-700 mr-4" />
            <div>
              <h4 className="text-lg font-medium">Visit Us</h4>
              <p className="text-gray-700">
                Aura Aesthetics Clinic, 40a High Street, Rochester, Kent. ME1
                1LD
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Success Modal"
      >
        <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
        <p className="text-gray-700">Our team will contact you soon.</p>
        <button
          onClick={closeModal}
          className="mt-6 bg-[#c16240] text-white py-2 px-4 hover:bg-yellow-600 transition-colors duration-300 "
        >
          Close
        </button>
      </Modal>
    </section>
  );
};

export default ContactFormWithInfo;
