import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ContactInfo from "../Components/Contact/ContactInfo";
import GoogleMap from "../Components/Contact/GoogleMap";
import Form from "../Components/Contact/Form";
import DynamicBanner from "../Components/DynamicBanner";
import VIPVisit from '../Components/Contact/VIPVisit';
import Appointment from '../Components/Appointment';
import WhatsappButton from '../Components/WhatsappButton';
import useDocumentTitle from '../useDocumentTitle';

const Contact = () => {
  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'Contact', href: '/contact' }
  ];
  useDocumentTitle('Contact Us - Aesthetics Ocean');
 
  return (

    
    <div className="min-h-screen flex flex-col">
      <Header />
      <WhatsappButton/>
      <main className="flex-grow">
        <DynamicBanner title="Contact Us" breadcrumbs={breadcrumbs} />
        <ContactInfo />
        <section className="bg-gray-50 py-12">
      <div className="container mx-auto px-6 lg:px-20">
        <div className="bg-white border border-gray-300 rounded-lg p-8">
          <h2 className="text-2xl lg:text-3xl font-semibold text-center mb-6">Get in touch</h2>
          <p className="text-center text-gray-700 mb-4">
          Begin your journey to flawless skin today. Whether you’re looking to enhance your natural beauty or address a specific concern, Aesthetic Ocean is here to help. 
            Click on <span className="text-[#c16240] font-bold">BOOK APPOINTMENT</span>  to arrange your appointment with our online booking system. If you’re having trouble booking, we’d be happy to book your appointment over the phone.
          </p>
          <p className="text-center text-gray-700 mb-4">
          We are dedicated to providing you with all the information you need to make informed decisions about your treatments. At Aesthetic Ocean, we ensure that your journey to beautiful skin is smooth, enjoyable, and personalized to your needs.  </p>
          <p className="text-center text-gray-700">
            Don’t wait any longer to achieve the look and feel you deserve. Book your appointment today and let our friendly team help you on your journey to dream skin!
          </p>
        </div>
      </div>
    </section>
    <VIPVisit/>
        <Form />
        <GoogleMap />
      </main>
      <Appointment/>
      <Footer />
    </div>
  );
};

export default Contact;
