import React from "react";
import { FaUserMd, FaShieldAlt, FaListAlt, FaHandHoldingHeart ,FaBullseye} from 'react-icons/fa';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import DynamicBanner from "../Components/DynamicBanner"
import BookAppointment from '../Components/BookAppointment'
import { Link } from "react-router-dom";
import WhatsappButton from "../Components/WhatsappButton";
import useDocumentTitle from "../useDocumentTitle";

const treatments = [
  {
    title: "Dermal Filler",
    image: `${process.env.PUBLIC_URL}/assets/Images/Dermal Fillers.jpg`,
    link: "#",
  },
  {
    title: "Mesotherapy",
    image: `${process.env.PUBLIC_URL}/assets/Images/Mesotherapy.jpg`,
    link: "#",
  },
  {
    title: "Anti-Wrinkle",
    image: `${process.env.PUBLIC_URL}/assets/Images/Anti Wrinkle.jpg`,
    link: "#",
  },
];

const About = () => {

  useDocumentTitle('About Us - Aesthetics Ocean');
  const breadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'About Us', href: '/about' }
  ];

  const handleButtonClick = () => {
    console.log("Button clicked!");
  };
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header/>
      <WhatsappButton/>
      <main className="flex-grow">
      <DynamicBanner title="ABOUT US" breadcrumbs={breadcrumbs} />
      </main>
    
    {/* About Clinic*/}
      <section className="bg-gray-100 py-12 relative">
      <div className="container mx-auto px-4 lg:px-20 relative">
        <div className="flex flex-col lg:flex-row items-center">
          
          {/* Image and Label */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-start mb-8 lg:mb-0 relative">
            {/* Dotted Background */}
            <div className="absolute inset-0 flex justify-center items-center">
              <div className="bg-dotted-pattern bg-cover w-60 h-60 -left-10 -top-10 lg:-left-20 lg:-top-20 absolute z-0"></div>
            </div>

            <div className="relative z-10">
              <img
                src={`${process.env.PUBLIC_URL}/assets/clinic.jpg`}
                alt="Our Team"
                className="rounded-lg shadow-lg relative z-10"
              />
            </div>
          </div>

          {/* Text Content */}
          <div className="w-full lg:w-1/2 lg:pl-12">
            <h2 className="text-2xl lg:text-3xl font-bold mb-6">
              YOUR TRUSTED PARTNER IN SKINCARE.
            </h2>
            <p className="text-gray-700 mb-4 text-sm  ">
            Aesthetic Ocean is an innovative clinic that expertly combines art, science, and wellbeing in our approach to aesthetics. Recognized as a leading practice in London, we are known for delivering personalized treatments with consistently exceptional results. </p>
            <p className="text-gray-700 mb-4 text-sm  ">
            We specialize in non-invasive procedures for the face, body, and intimate areas, using the latest technologies and evidence-based methods to achieve transformative outcomes. Our commitment to excellence ensures that every treatment is performed with top-quality equipment and materials. </p>
            <p className="text-gray-700 mb-4 text-sm  ">
            At Aesthetic Ocean, we offer a comprehensive range of services to meet all your aesthetic needs, providing solutions for any skin or body concerns in one convenient location.</p>
            <BookAppointment onClick={handleButtonClick}> Book Free Consultation</BookAppointment>
          </div>
        </div>
      </div>
    </section>
    {/* About Clinic */}
   
    {/*Bannar */}
    <section className="relative bg-cover bg-center h-60 flex items-center justify-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic.jpg)` }}>
      <div className="absolute inset-0 bg-white opacity-50"></div>
      <div className="relative z-10 text-center">
        <p className="text-gray-700 text-lg lg:text-xl mb-4">
          We’re here to help you achieve natural-looking results whatever your concerns
        </p>
        <a href="/contact" className="inline-block bg-[#c16240] text-white py-2 px-4">
          CONTACT US
        </a>
      </div>
    </section>
     {/*Bannar */}

     <section className="bg-white py-12 px-4">
      <div className="container mx-auto text-center w-full">
        <h2 className="text-xl lg:text-2xl font-semibold mb-6">
          PATIENT SATISFACTION IS KEY
        </h2>
        <p className="text-gray-500 text-sm  max-w-4xl mx-auto leading-relaxed">
        Patient satisfaction is central to our mission, which is why we conduct thorough consultations before any treatment. We carefully assess each patient's medical history and desired outcomes, recommending only the procedures we believe are best suited to their needs. Our ethical approach ensures that every service offered is designed to create an unforgettable experience.

From a personalized welcome health shot to a bespoke treatment plan tailored to your concerns, lifestyle, and budget, every detail is meticulously planned. Our goal is to make clients look and feel their best, offering a wide range of face and body treatments that deliver visible, long-lasting results.

All treatments and products at Aesthetic Ocean are carefully researched and tested by the founders before being introduced to the clinic, ensuring the highest standards of care and effectiveness for our patients.  </p>
      </div>
    </section>

    <section
      className="relative bg-cover bg-center py-16"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg.jpg)`,
      }}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-gray-100 to-gray-200 opacity-80"></div>

      {/* Content */}
      <div className="container relative mx-auto text-center px-6">
        <h2 className="text-2xl lg:text-3xl font-bold mb-16 text-gray-800">
          SO HOW DO WE ACHIEVE ALL OF THIS?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div className="group">
            <div className="flex justify-center mb-4">
              <FaUserMd className="text-4xl text-[#c16240] group-hover:scale-110 transition-transform duration-300" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-[#c16240] transition-colors duration-300">EXPERTS</h3>
            <p className="text-gray-600 text-sm ">
              All specialists are selectively hand-picked and all have more than 10 years’ experience.
            </p>
          </div>
          <div className="group">
            <div className="flex justify-center mb-4">
              <FaShieldAlt className="text-4xl text-[#c16240] group-hover:scale-110 transition-transform duration-300" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-[#c16240] transition-colors duration-300">SAFETY</h3>
            <p className="text-gray-600 text-sm ">
              We test everything we introduce thoroughly and work with FDA & CE mark technology only.
            </p>
          </div>
          <div className="group">
            <div className="flex justify-center mb-4">
              <FaListAlt className="text-4xl text-[#c16240] group-hover:scale-110 transition-transform duration-300" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-[#c16240] transition-colors duration-300">CHOICE</h3>
            <p className="text-gray-600 text-sm ">
              Extensive list of treatments for every skin and body need.
            </p>
          </div>
          <div className="group">
            <div className="flex justify-center mb-4">
              <FaHandHoldingHeart className="text-4xl text-[#c16240] group-hover:scale-110 transition-transform duration-300" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-[#c16240] transition-colors duration-300">PERSONAL ATTENTION</h3>
            <p className="text-gray-600 text-sm ">
              No one client’s treatment plan is the same as the other – vast variety of treatment choices.
            </p>
          </div>
        </div>
      </div>
    </section>


<section className="bg-white py-12">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl lg:text-4xl font-bold mb-6 text-gray-800">Treatments</h2>
        <p className="max-w-1xl mx-auto text-sm text-gray-500 mb-12">
          At Aesthetic Ocean, we put your safety and satisfaction first. All of our treatments are performed in a comfortable and sterile environment, and our team is committed to providing you with the highest level of care and attention. We believe that natural enhancements can help you feel confident and beautiful, and we’re here to help you achieve your desired results.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {treatments.map((treatment, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-lg shadow-lg group"
            >
              <img
                src={treatment.image}
                alt={treatment.title}
                className="w-full h-64 object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <h3 className="text-xl font-semibold mb-2">{treatment.title}</h3>
                <a
                  href={treatment.link}
                  className="bg-white text-gray-800 py-2 px-4 rounded-full hover:bg-gray-200 transition-colors duration-300"
                >
                  View More
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8">
          <a
            className="inline-block bg-[#c16240] text-white py-2 px-6 "
          >
           <Link to="/treatments">View All</Link> 
          </a>
        </div>
      </div>
    </section>

      <Footer />
   </div>
  );
};
export default About;
