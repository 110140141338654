import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import DynamicBanner from "../Components/DynamicBanner";
import { Link } from "react-router-dom";
import Appointment from '../Components/Appointment';
import WhatsappButton from '../Components/WhatsappButton';
import useDocumentTitle from '../useDocumentTitle';

const treatments = [
  {
    title: "Dermal Filler",
    description: "Enhance facial contours and volume, targeting areas like lips and nasolabial folds for a youthful appearance",
    image: `${process.env.PUBLIC_URL}/assets/Images/Dermal Fillers.jpg`,
    link: "/dermafiller",
  },
  {
    title: "Anti-Wrinkle Injections",
    description: "Smooth out fine lines and wrinkles for a naturally refreshed look",
    image: `${process.env.PUBLIC_URL}/assets/Images/Anti Wrinkle.jpg`,
    link: "/AntiWrinkles",
  },
  {
    title: "Mesotherapy",
    description: "Revitalize your skin with nutrient-rich injections that hydrate, firm, and brighten",
    image: `${process.env.PUBLIC_URL}/assets/Images/Mesotherapy.jpg`,
    link: "/Mesotherapy",
  },
  {
    title: "Microneedling",
    description: "Stimulate collagen production to improve skin texture, tone, and elasticity",
    image: `${process.env.PUBLIC_URL}/assets/Images/Microneedling.jpg`,
    link: "/Microneedling",
  },
 
  {
    title: "RF Fractional",
    description: "Tighten skin and reduce wrinkles with advanced radiofrequency therapy",
    image: `${process.env.PUBLIC_URL}/assets/Images/RF Fractional.jpg`,
    link: "/RFFractional",
  },
  {
    title: "Skin Booster",
    description: "Hydrate and rejuvenate your skin for a radiant, youthful glow",
    image: `${process.env.PUBLIC_URL}/assets/Images/Skin Booster.jpeg`,
    link: "/SkinBooster",
  },
  {
    title: "HydraFacial",
    description: "Cleanse, exfoliate, and hydrate your skin for a radiant complexion.",
    image: `${process.env.PUBLIC_URL}/assets/Images/Hydrafacial.jpg`,
    link: "/Hydrafacial",
  },
  
  {
    title: "Hair Restoration",
    description: "Encourage healthy hair growth and restore thinning hair",
    image: `${process.env.PUBLIC_URL}/assets/Images/hair restoration.jpeg`,
    link: "/HairRestoration",
  },
  {
    title: "Pigmentation Treatment",
    description: "Brighten and even out skin tone with our powerful pigmentation treatments",
    image: `${process.env.PUBLIC_URL}/assets/Images/Pigmentation.jpg`,
    link: "/PigmentationTreatment",
  },
  {
    title: "Vitamin B12 Injections",
    description: "Boost energy and vitality with our quick and effective Vitamin B12 shots.",
    image: `${process.env.PUBLIC_URL}/assets/Images/Injections.jpg`,
    link: "/Injections",
  },
 
];

const TreatmentsPage = () => {

  useDocumentTitle('Our Treatments - Aesthetics Ocean');

    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
  
  return (

    <div className="min-h-screen flex flex-col">
    <Header/>
    <WhatsappButton/>
    <main className="flex-grow">
    <DynamicBanner title="Treatments" breadcrumbs={breadcrumbs} />
    </main>
    <section className="bg-white py-12">
      <div className="container mx-auto px-8 lg:px-24 text-center">
        <h2 className="text-3xl lg:text-4xl font-bold mb-8 text-gray-800">Our Treatments</h2>
        <p className="max-w-1xl mx-auto text-sm text-gray-500 mb-12">
          At Aesthetic Ocean, we put your safety and satisfaction first. All of our treatments are performed in a comfortable and sterile environment, and our team is committed to providing you with the highest level of care and attention. We believe that natural enhancements can help you feel confident and beautiful, and we’re here to help you achieve your desired results.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {treatments.map((treatment, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-lg shadow-lg group"
            >
              <img
                src={treatment.image}
                alt={treatment.title}
                className="w-full h-80 object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-4 transition-opacity duration-500">
                <h3 className="text-xl font-semibold mb-2">{treatment.title}</h3>
                <p className="text-sm mb-4">{treatment.description}</p>
                <a
                
                  className="bg-[#c16240] text-white py-2 px-4 rounded-full hover:bg-yellow-600 transition-colors duration-300"
                > <Link to={treatment.link}>
                  View More</Link>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </section>
    <Appointment/>
    <Footer/>
    </div>
  );
};

export default TreatmentsPage;
