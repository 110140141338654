import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';

const RFFractional= () => {

  useDocumentTitle('RF-Fractional- Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesRFFractional = [
        { name: "1 Session", price: "£550" },
        { name: "2 Sessions ", price: "£1000" }
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/RF2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/RF1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/RF3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DOES RF FRACTIONAL COST?",
          answer: `During your consultation, we will discuss your specific skin concerns and goals. Based on your needs, we will recommend the appropriate number of RF Fractional sessions. The cost varies depending on the treatment area and the number of sessions required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "IS RF FRACTIONAL SAFE FOR ALL SKIN TYPES?",
          answer: `Yes, RF Fractional is safe for all skin types. The treatment is customized to suit your skin's needs, ensuring optimal results while minimizing the risk of side effects. We’ll assess your skin type during the consultation to tailor the treatment specifically for you.`,
        },
        {
          question: "HOW LONG WILL THE RESULTS LAST?",
          answer: `The results from RF Fractional treatments can last up to 12 months or longer, depending on your skin type, age, and lifestyle. Maintenance sessions may be recommended to prolong the effects. We’ll provide you with a personalized treatment plan to help you achieve and maintain the best results.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="RF Fractional" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="RF Fractional"
      description="RF Fractional is a cutting-edge non-surgical aesthetic treatment that rejuvenates and tightens the skin by combining radiofrequency energy with microneedling. This advanced procedure targets the deeper layers of the skin, stimulating collagen production and improving skin texture, tone, and firmness."      offer="Limited Time Offer!"
      description2="Ideal for those seeking to reduce fine lines, wrinkles, acne scars, and skin laxity, RF Fractional delivers noticeable results with minimal downtime. Experience a smoother, more youthful complexion tailored to your skin's needs."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/RF Fractional.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">RF Fractional Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How RF Fractional Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        RF Fractional treatments can rejuvenate the skin, reduce wrinkles, tighten loose skin, and improve overall skin texture by targeting the deeper layers of the skin with radiofrequency energy combined with microneedling. This innovative approach stimulates collagen production and enhances skin firmness, making it ideal for addressing fine lines, acne scars, and skin laxity. Suitable for all skin types, RF Fractional offers a non-surgical solution to achieving smoother, younger-looking skin.   </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All RF Fractional treatments are personally administered to ensure the highest quality of care and effective results.
        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="RF Fractional"
          serviceDescription="Tighten skin and reduce wrinkles with advanced radiofrequency therapy."
          services={servicesRFFractional}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default RFFractional;
