import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactInfo = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div className="flex flex-col items-center">
            <FaMapMarkerAlt className="text-[#c16240] text-4xl mb-4" />
            <h3 className="text-lg font-semibold mb-2">Our Location</h3>
            <a className="text-gray-600 text-sm" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("1st Floor, 406 Edgware Road, London W2 1ED")}`} target="_blank" rel="noopener noreferrer">
                  1st Floor, 406 Edgware Road, London W2 1ED
                </a>
           
          </div>
          <div className="flex flex-col items-center">
            <FaPhoneAlt className="text-[#c16240] text-4xl mb-4" />
            <h3 className="text-lg font-semibold mb-2">Phone Number</h3>
            <a href="tel:+447398165652" className="text-gray-600 text-sm">+447398165652</a>
           
          </div>
          <div className="flex flex-col items-center">
            <FaEnvelope className="text-[#c16240] text-4xl mb-4" />
            <h3 className="text-lg font-semibold mb-2">Email Address</h3>
            <a href="mailto:info@aestheticsocean.co.uk" className="text-gray-600 text-sm">info@aestheticsocean.co.uk</a>
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
