import React from 'react';

const DynamicBanner = ({ title, breadcrumbs, bgImage }) => {
  return (
    <section
      className="relative py-8 sm:py-8 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bannarbg.jpeg)` }}
    >
      <div className="absolute inset-0 bg-[#c16240] opacity-70"></div>
      <div className="relative container mx-auto px-4 lg:px-20 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <h1 className="text-xl sm:text-2xl lg:text-3xl text-white font-semibold mb-4 sm:mb-0">
          {title}
        </h1>
        <nav className="text-white text-sm sm:text-base">
          {breadcrumbs.map((breadcrumb, index) => (
            <span key={index}>
              <a href={breadcrumb.href} className="hover:underline">
                {breadcrumb.label}
              </a>
              {index < breadcrumbs.length - 1 && ' / '}
            </span>
          ))}
        </nav>
      </div>
    </section>
  );
};

export default DynamicBanner;
