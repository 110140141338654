import React from 'react';
import BookAppointment from '../BookAppointment';

const MainBannar= () => {
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

    return (
        
    <section className="bg-gray-50 flex flex-col lg:flex-row items-center lg:items-start py-10 px-5 lg:px-20">
    <div className="lg:w-1/2 text-center lg:text-left mb-10 lg:mb-0">
      <h1 className="text-3xl lg:text-5xl font-bold mb-4">Your Journey to <br/>Beautiful Skin &<br/> Body Starts Here</h1>
      <p className="text-gray-500 mb-6 pr-7">
     
      Enhance your natural beauty with non-surgical aesthetic skin and body treatments at Aesthetics Ocean. Our trusted doctors and skilled practitioners provide personalized care for men and women, offering advanced skin rejuvenation, body contouring, and anti-aging solutions. Experience safe, effective, and minimally invasive treatments in a luxurious environment. Rediscover your confidence with Aesthetics Ocean today. </p>
   
      <BookAppointment onClick={handleButtonClick}> Book Free Consultation</BookAppointment>
    </div>
    <div className="lg:w-1/2">
      <img src={`${process.env.PUBLIC_URL}/assets/Images/bannar.jpg`} alt="Aesthetic Clinic" className="w-full h-auto rounded-lg" />
    </div>
  </section>
  
    );
};

export default MainBannar;
