import React from "react";

const BookAppointment = ({ children, onClick }) => {
  return (
    <button
      className="bg-[#c16240] text-white py-2 px-4 mt-4"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default BookAppointment;
