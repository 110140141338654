import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Slider from "../Components/Home/Slider";
import MainBannar from "../Components/Home/MainBannar";
import AboutSafety from "../Components/Home/AboutSafety";
import WhyUs from "../Components/Home/WhyUs";
import WhatsappButton from "../Components/WhatsappButton";
import useDocumentTitle from "../useDocumentTitle";


const Home = () => {
  useDocumentTitle('Home - Aesthetics Ocean');

  return (
    
    <>
      <Header />
     <MainBannar />
     <WhatsappButton/>

      {/* Section 2 Start*/}
      <section className="bg-white py-10 px-5 lg:px-20 text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl lg:text-4xl font-semibold mb-4">
            Holistic Aesthetic Treatments
          </h2>
          <p className="text-gray-500 mb-6">
            At Aesthetic Ocean, we specialize in a diverse range of non-surgical
            cosmetic treatments designed to meet your individual needs and
            goals. By leveraging cutting-edge technology and advanced medical
            aesthetic techniques, we ensure natural and stunning results.
          </p>
          <p className="text-gray-500 mb-6">
            Explore our website to learn more about our services, read
            testimonials from our satisfied clients, and book your appointment.
            At Aesthetic Ocean, we are dedicated to enhancing your natural
            beauty and helping you feel your very best.
          </p>
          <hr className="border-t border-[#a88a1e] my-6 mx-auto w-1/2 lg:w-1/4" />
        </div>
      </section>

      {/*Section 2 End*/}

      <AboutSafety />
      <WhyUs />
      <Slider />
      <Footer />
    
    </>
  );
};

export default Home;
