import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFolder } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const BlogCard = ({ blog }) => {
    return (
        <div className="w-full bg-white border border-gray-300 overflow-hidden shadow-lg rounded-lg">
            <div className="relative">
                <img 
                    src={blog.image} 
                    alt={blog.title} 
                    className="w-full h-48 object-cover sm:h-56 md:h-64"
                />
                <div className="absolute top-0 left-0 transform -translate-x-2 -translate-y-2">
                    <div 
                        className="relative bg-gradient-to-r from-teal-500 to-green-500 text-white text-center py-1.5 px-3 sm:py-2 sm:px-4 rounded-md shadow-lg"
                        style={{ boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.3)' }}>
                        <p className="text-lg font-bold leading-none">{blog.date.day}</p>
                        <p className="text-xs uppercase tracking-widest">{blog.date.month}</p>
                        <div 
                            className="absolute top-0 right-0 w-0 h-0 border-t-8 border-r-8 border-transparent border-r-white"
                            style={{ top: '-4px', right: '-4px' }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-6">
                <div className="flex items-center text-xs sm:text-sm text-gray-500 mb-2">
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-[#c16240]" />
                    <span className="mr-2 text-[#c16240]">{blog.author}</span>
                    <span className="mx-2">|</span>
                    <FontAwesomeIcon icon={faFolder} className="mr-2 text-[#c16240]" />
                    <Link  to="/blogdetail" state={{ blog }}className="text-[#c16240]">
                        <span>{blog.content}</span>
                    </Link>
                </div>
                <h3 className="text-base sm:text-lg md:text-xl font-semibold mb-4 leading-tight">
                    {blog.title}
                </h3>
            </div>
        </div>
    );
};

export default BlogCard;
