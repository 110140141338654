import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const HairRestoration= () => {

  useDocumentTitle('HydraFacial - Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesHydrafacial= [
        { name: "HP(Antiagening) - 1 Session", price: "£199" },
        { name: "HP(Sun Damage & Pigmentation) - 1 Session", price: "£199" },
        { name: "HP(Clear Skin) - 1 Session", price: "£199" },
        { name: "HP(Signature) - 1 Session", price: "£250" },
        { },
        { name: "HP(Antiagening) - 6 Session", price: "£1170"  },
        { name: "HP(Sun Damage & Pigmentation) - 6 Session", price: "£1170"  },
        { name: "HP(Clear Skin) - 6 Session", price: "£1170"  },
        { name: "HP((Signature) - 6 Session", price: "£1450"  },
      ];
    
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/HF1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/hf1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/hp2.jpeg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DOES A HYDRAFACIAL COST?",
          answer: `During your consultation, we will assess your skin concerns and recommend the appropriate HydraFacial treatment plan. The cost varies depending on the specific treatment and any additional enhancements. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "IS HYDRAFACIAL SUITABLE FOR ALL SKIN TYPES?",
          answer: `Yes, HydraFacial is suitable for all skin types, including sensitive skin. This treatment is customizable to address a variety of skin concerns, from dryness and dullness to oily and congested skin, ensuring effective results for everyone.`,
        },
        {
          question: "HOW LONG WILL IT TAKE TO SEE RESULTS FROM HYDRAFACIAL?",
          answer: `Results from HydraFacial treatments are typically immediate, with skin appearing more hydrated, smooth, and radiant right after the session. For lasting results, regular treatments may be recommended. We’ll provide you with a personalized treatment schedule during your consultation.`,
        },
      ];

  return (
  
  <>
  <Header/>
  <WhatsappButton/>
   
    <DynamicBanner title="HydraFacial" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="HydraFacial"
      description="HydraFacial is a popular non-invasive treatment designed to cleanse, exfoliate, and hydrate your skin. This multi-step procedure uses advanced technology to remove dead skin cells and impurities while delivering moisturizing serums tailored to your skin’s needs. The result is a refreshed, radiant complexion with improved texture and tone."      offer="Limited Time Offer!"
      description2="Whether you’re looking to rejuvenate dull skin or maintain a glowing complexion, HydraFacial offers a customized solution for all skin types. Book a consultation to discover how this treatment can enhance your skin's health and appearance."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/Hydrafacial.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Hair Restoration Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How HydraFacial Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        HydraFacial treatments are designed to deeply cleanse, exfoliate, and hydrate the skin, offering a refreshed and radiant complexion. This non-invasive, multi-step procedure uses advanced technology to remove dead skin cells and impurities while simultaneously infusing the skin with nourishing serums. The treatment improves skin texture, tone, and overall appearance, leaving your skin looking healthy and rejuvenated. Suitable for all skin types, HydraFacial provides a customizable solution for your skincare needs.</p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All HydraFacial treatments are administered with precision and care to ensure optimal results.

        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="HydraFacial"
          serviceDescription="Cleanse, exfoliate, and hydrate your skin for a radiant complexion."
          services={servicesHydrafacial}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default HairRestoration;
