import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const Microneedling = () => {

  useDocumentTitle('Microneedling- Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesMicroneedling = [
        { name: "Face and Neck", price: "£250" },
        { name: "Face and Neck (3 Courses)", price: "£650" },
        { name: "Full Face (3 Courses)", price: "£500" },
      ];
    
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/MN1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/MN2.jpeg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/MN3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DOES MICRONEEDLING COST?",
          answer: ` During your consultation, we will discuss your specific skin concerns and treatment goals. Based on your needs, we will recommend the appropriate number of microneedling sessions. The cost varies depending on the treatment area and the number of sessions required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "IS THERE ANY DOWNTIME AFTER MICRONEEDLING?",
          answer: `Microneedling has minimal downtime. You may experience some redness and mild swelling immediately after the procedure, which typically subsides within 24 to 48 hours. We'll provide you with aftercare instructions to ensure optimal healing and results.`,
        },
        {
          question: "HOW MANY SESSIONS WILL I NEED TO SEE RESULTS?",
          answer: `The number of sessions required varies based on your individual skin concerns and goals. Generally, a series of 3 to 6 sessions spaced 4 to 6 weeks apart is recommended for best results. We'll customize a treatment plan for you during your consultation.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Microneedling" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Microneedling"
      description="Microneedling is a popular non-surgical aesthetic treatment that enhances skin texture, reduces scars, and promotes a youthful appearance. This procedure involves using fine needles to create micro-injuries in the skin, stimulating the body's natural healing process and boosting collagen production."      offer="Limited Time Offer!"
      description2="Experience the transformative effects of microneedling. Whether you're looking to reduce fine lines, acne scars, or improve overall skin texture, microneedling can be tailored to meet your specific needs."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Treatments/q-01.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Microneedling Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How Microneedling Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Microneedling can enhance skin texture, reduce the appearance of fine lines, wrinkles, acne scars, and other skin imperfections, while effectively improving skin tone and firmness. This procedure uses advanced microneedling devices that are safe, effective, and promote long-lasting results. Suitable for all skin types and ages, microneedling offers a non-surgical solution to revitalizing your skin and achieving a smoother, more youthful appearance. </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Microneedling treatments are personally administered to ensure the highest standards of care and optimal results.  </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Microneedling"
          serviceDescription="Stimulate collagen production to improve skin texture, tone, and elasticity."
          services={servicesMicroneedling }
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default Microneedling;
