import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import TreatmentsPage from './Pages/TreatmentsPage'
import Price from './Pages/Pricing '
import Contact from './Pages/Contact';
import Dermafiller from './Pages/Treatments/Dermafiller';
import AntiWrinkles from './Pages/Treatments/AntiWrinkles';
import Mesotherapy from './Pages/Treatments/Mesotherapy ';
import Microneedling from './Pages/Treatments/Microneedling';
import RFFractional from './Pages/Treatments/RFFractional';
import SkinBooster from './Pages/Treatments/SkinBooster';
import HairRestoration from './Pages/Treatments/HairRestoration';
import PigmentationTreatment from './Pages/Treatments/PigmentationTreatment';
import Injections from './Pages/Treatments/Injections';
import Hydrafacial from './Pages/Treatments/Hydrafacial'
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetails';


const App = () => {
  return (
    <Router>
    <div className="App">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/treatments" element={<TreatmentsPage/>} />
          <Route path="/dermafiller" element={<Dermafiller/>} />
          <Route path="/AntiWrinkles" element={<AntiWrinkles/>} />
          <Route path="/Mesotherapy" element={<Mesotherapy/>} />
          <Route path="/Microneedling" element={<Microneedling/>} />
          <Route path="/RFFractional" element={<RFFractional/>} />
          <Route path="/SkinBooster" element={<SkinBooster/>} />
          <Route path="/HairRestoration" element={<HairRestoration/>} />
          <Route path="/PigmentationTreatment" element={<PigmentationTreatment/>} />
          <Route path="/Hydrafacial" element={<Hydrafacial/>} />
          <Route path="/Injections" element={<Injections/>} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog"  element={<Blog/>} />
          <Route path="/blogdetail"  element={<BlogDetail/>} />  
        </Routes>
      </div>
    </Router>
  );
};

export default App;
