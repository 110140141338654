import React from "react";
import BookAppointment from '../BookAppointment';

import {
    FaUserMd,
    FaClipboardList,
    FaShieldAlt,
    FaUserFriends,
  } from "react-icons/fa";

const WhyUs = () => {
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };
  return (
    <section className="bg-white py-10 px-5 lg:px-20 text-center">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-2xl lg:text-3xl font-semibold mb-10">
          WHY AESTHETICS OCEAN?
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 mb-10">
          <div className="flex flex-col items-center">
            <FaUserMd className="text-5xl text-[#c16240] mb-4" />
            <h3 className="text-xl font-semibold mb-2">EXPERTS</h3>
            <p className="text-gray-500">
              All specialists are selectively hand-picked and <br />
              all have more than 10 years' experience.
            </p>
          </div>

          <div className="flex flex-col items-center">
            <FaClipboardList className="text-5xl text-[#c16240] mb-4" />
            <h3 className="text-xl font-semibold mb-2">CHOICE</h3>
            <p className="text-gray-600">
              Extensive list of treatments for every
              <br /> skin and body need.
            </p>
          </div>

          <div className="flex flex-col items-center">
            <FaShieldAlt className="text-5xl text-[#c16240] mb-4" />
            <h3 className="text-xl font-semibold mb-2">SAFETY</h3>
            <p className="text-gray-600">
              We test everything we introduce thoroughly <br />
              and only work with FDA & CE mark technology.
            </p>
          </div>

          <div className="flex flex-col items-center">
            <FaUserFriends className="text-5xl text-[#c16240] mb-4" />
            <h3 className="text-xl font-semibold mb-2">PERSONAL ATTENTION</h3>
            <p className="text-gray-600">
              No two client’s treatment plans are the same.
            </p>
          </div>
        </div>

        <BookAppointment onClick={handleButtonClick}> Book Appointment</BookAppointment>
      </div>
    </section>
  );
};

export default WhyUs;
