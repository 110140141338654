import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const Dermafiller = () => {

  useDocumentTitle('DermaFiller - Aesthetics Ocean');

    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesDermalFiller = [
        { name: "Lip 1ML", price: "£300" },
        { name: "Nasolabial", price: "£300" },
    
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/DF2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/df1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/DF3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DO LIP FILLERS COST?",
          answer: `During your consultation, we will discuss your specific goals and areas of concern. Based on your needs, we will recommend the appropriate amount of filler. The price varies depending on the treatment area and the amount of filler used. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "I'M PREGNANT, CAN I HAVE LIP FILLERS?",
          answer: `Currently, there is no comprehensive research on the safety of dermal fillers during pregnancy. Therefore, we do not recommend dermal filler treatments for pregnant women. Your safety is our priority.`,
        },
        {
          question: "HOW LONG WILL THE RESULTS LAST?",
          answer: `Dermal filler results typically last between 6 to 18 months, depending on the area treated, the type of filler used, and individual factors. We’ll provide you with detailed information during your consultation to help you understand what to expect.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Dermal Filler" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Dermal Fillers"
      description="Dermal fillers are a popular non-surgical aesthetic treatment that restore volume, structure, and definition to the face. They consist of injectable gels made primarily of hyaluronic acid, a natural substance that hydrates and plumps the skin."      offer="Limited Time Offer!"
      description2="Specialising in anti-ageing and natural enhancement. Unsure of which treatment would suit you? Book in for a complimentary facial assessment and advice with one of our cosmetic."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/Dermal Fillers.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Dermal Fillers Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
          How Dermal Fillers Work?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
          Dermal fillers at Aesthetic Ocean can enhance lips, cheeks, chin, jawline, temples, and the eye area, while effectively addressing fine lines, wrinkles, asymmetry, and skin laxity. Our clinic uses premium, CE-marked fillers that are safe, effective, and long-lasting. Suitable for men and women of all ages, dermal fillers offer a non-surgical solution to improving your appearance and achieving natural-looking results.
        </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
          All Dermal Filler treatments are carried out by our in-house Cosmetic Doctor.
        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Dermal Filler"
          serviceDescription="Enhance facial contours and volume, targeting areas like lips and nasolabial folds for a youthful appearance."
          services={servicesDermalFiller}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default Dermafiller;
