import React from "react";
import BookAppointment from '../BookAppointment';

const AboutSafety = () => {
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

  return (
    <section className="bg-gray-50 py-10 px-5 lg:px-20">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center relative">
        <div className="lg:w-1/2 bg-white p-8 rounded-lg shadow-lg z-10 relative lg:-mr-16 lg:mb-0">
          <h3 className="text-xs font-bold text-[#c16240] mb-2">
            Aesthetics Ocean <span className="text-gray-800">Ethics</span>
          </h3>
          <h2 className="text-3xl font-semibold mb-4">You are in safe hands</h2>
          <p className="text-gray-500 mb-4">
            Aesthetic Ocean delivers honest, transparent, and high-quality
            services, ensuring tailored treatments and outstanding results. Our
            expertise in non-surgical aesthetics shines through our impressive
            portfolio.
          </p>
          <p className="text-gray-500 mb-4">
            We prioritize patient care with thorough consultations,
            understanding needs and expectations to provide the best outcomes.
            At Aesthetic Ocean, patients are informed and involved throughout
            their treatment journey.
          </p>
         <BookAppointment onClick={handleButtonClick}> Book Free Consultation</BookAppointment>
        </div>

        <div className="lg:w-1/2 hidden lg:block">
          <img
            src={`${process.env.PUBLIC_URL}/assets/Images/Happy.jpg`}
            alt="How to find us"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSafety;
