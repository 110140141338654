import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok, FaWhatsapp, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import emailjs from "emailjs-com";


const Footer = () => {

  const [formData, setFormData] = useState({
    fullName: '',
    email: ''
  });
  const [message, setMessage] = useState(''); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs.sendForm(
      'service_0s58dsc',  // Replace with your EmailJS service ID
      'template_dzmzobc', // Replace with your EmailJS template ID
      e.target,
      'BtV8_OZXSsIVXVfHg' // Replace with your EmailJS user ID
    ).then((result) => {
      console.log(result.text);
      setMessage("Thanks for signing up for the newsletter! We'll be in touch soon.");
      setFormData({ fullName: '', email: '' }); // Reset the form after successful submission
    }, (error) => {
      console.log(error.text);
      setMessage("Subscription failed, please try again.");
    });
  };
  


  return (
    <footer className="bg-gray-100 text-gray-700 py-10">
      <div className="container mx-auto px-4 lg:mx-8"> {/* Added mx-8 for margin on both sides */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-start mb-10 space-y-8 lg:space-y-0 lg:space-x-10">
          
          {/* First Column */}
          <div className="flex-1 flex flex-col items-center lg:items-start">
            <h2 className="text-2xl lg:text-3xl font-semibold mb-2">
              <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="Aesthetic Ocean" className="h-10 w-50 lg:h-10 mr-2" />
            </h2>
            <br/>
            <p className="text-sm lg:text-base text-center lg:text-left text-gray-500 max-w-lg">
              At Aesthetic Ocean, we provide cutting-edge aesthetic treatments designed to enhance your natural beauty and boost your confidence.
            </p>
            <p className="text-lg lg:text-xl mt-4 font-bold text-black">Follow Us</p>
            <div className="flex justify-center lg:justify-start space-x-4 text-1xl mt-2">
              <a href="https://www.tiktok.com/@aestheticsocean?_t=8p6phZnnrzV&_r=1" target="_blank" rel="noopener noreferrer" className="bg-[#c16240] text-white py-3 px-3"><FaTiktok /></a>
              <a href="https://www.instagram.com/aestheticsoceanlondon?igsh=MTF3bzRia3RrNmFpaw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="bg-[#c16240] text-white py-3 px-3"><FaInstagram /></a>
              <a href="https://www.facebook.com/profile.php?id=61564935713183" target="_blank" rel="noopener noreferrer" className="bg-[#c16240] text-white py-3 px-3"><FaFacebookF /></a>
            </div>
          </div>
          
          {/* Second Column */}
          <div className="flex-1">
            <h3 className="font-semibold mb-2">Contact Info</h3>
            <p className="text-gray-500">Contact Us For Any Query & Booking Appointment</p>
            <ul className="space-y-4 text-gray-700 mt-4">
              <li className="flex items-start">
                <FaEnvelope className="text-[#c16240] mr-2 mt-1" />
                <a href="mailto:info@aestheticsocean.co.uk" className="text-gray-500">info@aestheticsocean.co.uk</a>
              </li>
              <li className="flex items-start">
                <FaPhoneAlt className="text-[#c16240] mr-2 mt-1" />
                <a href="tel:+447398165652" className="text-gray-500">+447398165652</a>
              </li>
              <li className="flex items-start">
                <FaMapMarkerAlt className="text-[#c16240] mr-2 mt-1" />
                <a className="text-gray-500" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("1st Floor, 406 Edgware Road, London W2 1ED")}`} target="_blank" rel="noopener noreferrer">
                  1st Floor, 406 Edgware Road, London W2 1ED
                </a>
              </li>
            </ul>
          </div>

          {/* Third Column */}
          <div className="flex-1">
            <h3 className="font-semibold mb-2">Quick Links</h3>
            <ul className="space-y-1">
              <li><Link to="/" className="hover:text-[#c16240] text-gray-500">Home</Link></li>
              <li><Link to="/about" className="hover:text-[#c16240] text-gray-500">About</Link></li>
              <li><Link to="/treatments" className="hover:text-[#c16240] text-gray-500">Treatments</Link></li>
              <li><Link to="/pricing" className="hover:text-[#c16240] text-gray-500">Pricing</Link></li>
              <li><Link to="/blog" className="hover:text-[#c16240] text-gray-500">Blog</Link></li>
              <li><Link to="/contact" className="hover:text-[#c16240] text-gray-500">Contact</Link></li>
            </ul>
          </div>

          {/* Fourth Column - Newsletter */}
          <div className="flex-1">
            <h3 className="font-semibold mb-2">Newsletter</h3>
            <p className="text-gray-500 mb-4">Subscribe to our newsletter to stay updated on our latest offers and treatments.</p>
            <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-2">
          <input 
            type="text" 
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            placeholder="Enter your full name" 
            className="p-2 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#c16240]" 
          />
          <input 
            type="email" 
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email" 
            className="p-2 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#c16240]" 
          />
          <button 
            type="submit" 
            className="bg-[#c16240] text-white p-2 w-full"
          >
            Subscribe
          </button>
        </div>
      </form>
            {message && (
        <p className="mt-4 text-green-600">{message}</p> // Conditionally render the message
      )}
           <a href="">
           {/* Google Reviews Section */}
           <div className="flex justify-center items-center mt-6 text-gray-700">
              <img src={`${process.env.PUBLIC_URL}/assets/Google-Review-Logo.png`} alt="Aesthetic Ocean" className="h-10 lg:h-20 mr-2" />
            </div>

            </a> 
          </div>
          
        </div>
        
        {/* Footer Bottom Section */}
        <div className="bg-gray-100 border-t border-gray-200 pt-4 pb-0 bottom-0 w-full">
          <div className="container mx-auto flex justify-between items-center">
            <div className="text-sm text-gray-600">
              © 2024 Aesthetics Ocean.
            </div>
            <div className="flex space-x-4 mr-8">
              <img src={`${process.env.PUBLIC_URL}/assets/visa.png`} alt="Visa" className="h-4 mr-2" />
              <img src={`${process.env.PUBLIC_URL}/assets/mastercard.png`} alt="Mastercard" className="h-4 mr-2" />
              <img src={`${process.env.PUBLIC_URL}/assets/paypal.png`} alt="PayPal" className="h-4 mr-2" />
              <img src={`${process.env.PUBLIC_URL}/assets/Maestro.png`} alt="Maestro" className="h-4 mr-2" />
              <img src={`${process.env.PUBLIC_URL}/assets/visaelectron.png`} alt="Visa Electron" className="h-4 mr-2" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
