import React from 'react';

const Price = ({ serviceName, serviceDescription, services }) => {
  return (
    <section className="bg-gray-50 py-6">
      <div className="container mx-auto px-6 lg:px-20">
        <h2 className="text-3xl lg:text-4xl font-bold text-center mb-4 text-gray-800">{serviceName}</h2>
        <p className="text-center text-gray-600 mb-8">{serviceDescription}</p>
        <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-3xl mx-auto">
          <table className="w-full bg-white text-left">
            <thead>
              <tr>
                <th className="py-4 pl-6 text-lg font-semibold text-gray-700 border-b border-gray-400 text-left">Services</th>
                <th className="py-4 pr-6 text-lg font-semibold text-gray-700 border-b border-gray-400 text-right">Clinic Price</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={index} className={index === 0 ? "border-t border-gray-400" : ""}>
                  <td className="py-4 pl-6 border-b border-gray-200 text-left">
                    <div className="text-gray-800 font-medium">{service.name}</div>
                  </td>
                  <td className="py-4 pr-6 border-b border-gray-200 text-gray-800 font-semibold text-right">{service.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Price;
