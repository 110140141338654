import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaMapMarkerAlt,FaEnvelope } from 'react-icons/fa';
import BookAppointment from '../Components/BookAppointment'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-2 flex flex-wrap justify-between items-center">
        <div className="flex items-center w-full md:w-auto justify-between">
          <div className="flex items-center">
            <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="Aesthetic Ocean" className="h-8 mr-2" />
          </div>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-gray-700 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <div className="hidden md:flex flex-col md:flex-row items-center space-x-0 md:space-x-8 w-full md:w-auto mt-2 md:mt-0">
          <div className="flex items-center space-x-2 mb-2 md:mb-0">
          <a href="tel:+447398165652"> <div className="bg-[#c16240] text-white py-3 px-3 ">
           <FaPhoneAlt className="text-white" />
            </div></a> 
            <div className="text-sm text-gray-500">
              <span>Call Us</span>
              <a href="tel:+447398165652" className="block text-[#c16240]">+447398165652</a>
            </div>
          </div>
          <div className="flex items-center space-x-2 mb-2 md:mb-0">
          <a href="mailto:info@aestheticsocean.co.uk" className="block text-[#c16240]">
          <div className="bg-[#c16240] text-white py-3 px-3 ">
              <FaEnvelope className="text-white" />
            </div></a>
            <div className="text-sm text-gray-500">
              <span>Email</span>
              <a href="mailto:info@aestheticsocean.co.uk" className="block text-[#c16240]">info@aestheticsocean.co.uk</a>
          
            </div>
          </div>
          <BookAppointment onClick={handleButtonClick}>Book Appointment</BookAppointment>
        </div>
      </div>
      <nav className="hidden md:block bg-gray-100 py-4">
        <ul className="container mx-auto px-4 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-16">
          <li className="cursor-pointer hover:text-[#c16240]"> <Link to="/">Home</Link></li>
          <li className="cursor-pointer hover:text-[#c16240]"><Link to="/about">About</Link></li>
          <li className="cursor-pointer hover:text-[#c16240]"><Link to="/treatments">Treatments</Link></li>
          <li className="cursor-pointer hover:text-[#c16240]"><Link to="/pricing">Pricing</Link></li>
          <li className="cursor-pointer hover:text-[#c16240]"><Link to="/blog">Blog</Link></li>
          <li className="cursor-pointer hover:text-[#c16240]"><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
      {isMenuOpen && (
        <div className="md:hidden bg-white py-2 px-4">
          <nav className="flex flex-col space-y-4">
            <a href="#" className="text-gray-700 hover:underline"> <Link to="/">Home</Link></a>
            <a href="#" className="text-gray-700 hover:underline"><Link to="/about">About</Link></a>
            <a href="#" className="text-gray-700 hover:underline"><Link to="/treatments">Treatments</Link></a>
            <a href="#" className="text-gray-700 hover:underline"><Link to="/pricing">Pricing</Link></a>
            <a href="#" className="text-gray-700 hover:underline"><Link to="/blog">Blog</Link></a>
            <a href="#" className="text-gray-700 hover:underline"><Link to="/contact">Contact</Link></a>
            <button className="bg-[#c16240] text-white py-2 px-4  mt-4">Book Appointment</button>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
