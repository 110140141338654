import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const Mesotherapy = () => {

  useDocumentTitle('Mesotherapy - Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesMesotherapy = [
        { name: "1 Session", price: "£300" },
        { name: "4 Session", price: "£1000" },
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/M1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/M2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/m3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DOES MESOTHERAPY COST?",
          answer: `During your consultation, we will discuss your specific skin concerns and goals. Based on your needs, we will recommend the appropriate mesotherapy treatment plan. The price varies depending on the treatment area and the number of sessions required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "CAN MESOTHERAPY BE COMBINED WITH OTHER TREATMENTS?",
          answer: `Yes, mesotherapy can be safely combined with other treatments like Botox, fillers, or chemical peels for enhanced results. We’ll provide personalized recommendations based on your skin’s needs during your consultation.`,
        },
        {
          question: "HOW LONG BEFORE I SEE RESULTS FROM MESOTHERAPY?",
          answer: ` Results from mesotherapy can typically be seen after 2-3 sessions, with full benefits becoming apparent over a series of treatments. We’ll provide you with a customized treatment plan and timeline during your consultation to help you achieve the best results.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Mesotherapy" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Mesotherapy"
      description="Mesotherapy is a popular non-surgical treatment that rejuvenates and revitalizes the skin, addressing issues such as fine lines, wrinkles, and sagging. It involves the injection of a customized blend of vitamins, enzymes, and hyaluronic acid to nourish and hydrate the skin, improving its overall appearance and texture."      offer="Limited Time Offer!"
      description2="Specializing in anti-aging and skin enhancement. Unsure of which treatment would suit you? Book a complimentary consultation to discuss your needs and receive personalized advice."
      phoneNumber="++44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Treatments/i-01.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Mesotherapy Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
        How Mesotherapy Works?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Mesotherapy can rejuvenate and refresh the skin on your face, neck, and hands, addressing fine lines, wrinkles, and dullness. The treatment involves micro-injections of a customized blend of vitamins, enzymes, and hyaluronic acid that are safe, effective, and tailored to your skin’s needs. Suitable for all skin types, mesotherapy offers a non-surgical solution to enhancing your appearance and achieving a radiant, youthful glow.   </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Mesotherapy treatments are personally administered to ensure the highest level of care and results.
        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Mesotherapy"
          serviceDescription="Revitalize your skin with nutrient-rich injections that hydrate, firm, and brighten."
          services={ servicesMesotherapy}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default Mesotherapy;
