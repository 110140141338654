import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const  Injections= () => {

  useDocumentTitle('Injections- Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesVitaminB12Injections = [
        { name: "Vit B12 Injection ", price: "£50" }
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/b12.jpeg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/b123.png`,
        `${process.env.PUBLIC_URL}/assets/Treatments/b122.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DO VITAMIN B12 INJECTIONS COST?",
          answer: `During your consultation, we will assess your specific needs and recommend an appropriate Vitamin B12 injection plan. The cost of the injections varies depending on the frequency and number of treatments required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "WHO SHOULD CONSIDER VITAMIN B12 INJECTIONS?",
          answer: `Vitamin B12 injections are ideal for individuals who are deficient in B12, such as vegetarians, vegans, and those with certain medical conditions that affect B12 absorption. They are also beneficial for anyone looking to boost energy levels, improve mood, and enhance cognitive function.`,
        },
        {
          question: "HOW SOON WILL I SEE RESULTS FROM VITAMIN B12 INJECTIONS?",
          answer: `Many people start to feel the effects of Vitamin B12 injections within a few days, experiencing increased energy and improved mood. However, the full benefits can vary depending on individual factors and the severity of the deficiency. Regular treatments may be recommended for sustained results.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Vitamin B12 Injections" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Vitamin B12 Injections"
      description="Vitamin B12 injections are a popular and effective way to boost your energy levels, support your immune system, and improve overall well-being. This treatment is especially beneficial for individuals with B12 deficiency, which can lead to fatigue, mood changes, and cognitive difficulties. By delivering B12 directly into the bloodstream, these injections ensure maximum absorption and quick results."      offer="Limited Time Offer!"
      description2="Ideal for those looking to increase their vitality and support their overall health. Book a consultation to find out if Vitamin B12 injections are right for you."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/Injections.jpg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Vitamin B12 Injections Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
          
How Vitamin B12 Injections Work?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Vitamin B12 injections work by delivering the vitamin directly into the bloodstream, bypassing the digestive system for rapid absorption and immediate benefits. Vitamin B12 is essential for energy production, red blood cell formation, and neurological function. When injected, it quickly boosts B12 levels in the body, helping to combat fatigue, improve mood, enhance cognitive function, and support overall well-being. This treatment is particularly effective for those with a B12 deficiency or those looking to enhance their energy levels and mental clarity.   </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Vitamin B12 injections are administered with care to ensure optimal results and safety.
        </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Vitamin B12 Injections"
          serviceDescription="Boost energy and vitality with our quick and effective Vitamin B12 shots."
          services={servicesVitaminB12Injections}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default Injections;
