import React from 'react';
import PageLayout from '../../Components/Services/PageLayout';
import DynamicBanner from '../../Components/DynamicBanner';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Price from '../../Components/Price';
import WhatsappButton from '../../Components/WhatsappButton';
import useDocumentTitle from '../../useDocumentTitle';


const SkinBooster= () => {
  useDocumentTitle('SkinBooster - Aesthetics Ocean');
    const breadcrumbs = [
        { label: 'Home', href: '/' },
        { label: 'Treatments', href: '/Treatments' }
      ];
      const servicesSkinBooster = [
        { name: "1 Session", price: "£300" },
        { name: "4 Sessions ", price: "£1000" }
      ];
      const images = [
        `${process.env.PUBLIC_URL}/assets/Treatments/sb2.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/sb1.jpg`,
        `${process.env.PUBLIC_URL}/assets/Treatments/sb3.jpg`,
      ];

      const faqs = [
        {
          question: "HOW MUCH DO SKIN BOOSTERS COST?",
          answer: `During your consultation, we will discuss your skin's needs and desired results. Based on this, we will recommend the appropriate Skin Booster treatment. The cost varies depending on the treatment area and the number of sessions required. Please contact us for detailed pricing information or to book a consultation.`,
        },
        {
          question: "CAN SKIN BOOSTERS BE USED WITH OTHER TREATMENTS?",
          answer: `Yes, Skin Boosters can be combined with other treatments such as fillers, microneedling, or laser therapies for enhanced results. During your consultation, we will create a tailored treatment plan to meet your specific skin goals.`,
        },
        {
          question: "HOW LONG DO THE RESULTS FROM SKIN BOOSTERS LAST?",
          answer: `Results from Skin Booster treatments can last up to 6 months, depending on your skin type, age, and lifestyle. We’ll provide guidance on how to maintain the benefits and may recommend follow-up sessions to prolong the results.`,
        },
      ];

  return (
  
  <>
  <Header/>
   <WhatsappButton/>
    <DynamicBanner title="Skin Booster" breadcrumbs={breadcrumbs} />
  
   
   <PageLayout
      title="Skin Booster "
      description="Skin Booster treatments are a popular non-surgical aesthetic option that deeply hydrates the skin, enhancing its overall texture, elasticity, and radiance. These treatments involve injecting a specialized hyaluronic acid-based formula beneath the skin's surface to provide long-lasting hydration and subtle volume, resulting in a naturally glowing complexion."      offer="Limited Time Offer!"
      description2="Ideal for those looking to improve skin texture, reduce fine lines, and achieve a fresh, dewy look. Book a complimentary consultation to discover how Skin Boosters can enhance your skin’s natural beauty."
      phoneNumber="+44 7398 165652"
      imageUrl={`${process.env.PUBLIC_URL}/assets/Images/Skin Booster.jpeg`}
    />
    <br/>

<div className="container mx-auto px-4 py-8">
      <h1 className="text-xl lg:text-2xl mb-6 font-bold text-center">Skin Booster Before & After</h1>
      <div className="flex justify-center space-x-4 mx-16">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Dermal Fillers ${index + 1}`}
            className="w-1/4 h-auto"
          />
        ))}
      </div>
    </div>

    <div
      className="relative container mx-auto px-4 py-16 text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/clinic2.jpg)`,
      }}
    >
      {/* Overlay for opacity */}
      <div className="absolute inset-0 bg-white opacity-70"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-1xl md:text-3xl mb-8 tracking-wide text-black">
          
How Skin Boosters Work?
        </h2>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        Skin Boosters deeply hydrate and rejuvenate the skin by delivering hyaluronic acid directly beneath the skin's surface. This treatment enhances skin texture, elasticity, and radiance, effectively reducing the appearance of fine lines and revitalizing dull, tired-looking skin. Suitable for all skin types, Skin Boosters offer a non-surgical solution for achieving a naturally glowing complexion.      </p>
        <p className="text-gray-700 max-w-4xl mx-auto leading-relaxed mb-6">
        All Skin Booster treatments are administered with care to ensure optimal results and a refreshed, youthful appearance.  </p>
      </div>
    </div>


    <div className="container mx-auto px-24 py-16 text-center bg-white">
      <h2 className="text-xl font-bold md:text-2xl mb-8 tracking-wide text-black">
        FREQUENTLY ASKED QUESTIONS
      </h2>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 text-left">
        {faqs.map((faq, index) => (
          <div key={index} className="md:w-1/3 mb-8 md:mb-0 px-4">
            <h4 className="text-lg text-md font-semibold text-black mb-4">{faq.question}</h4>
            <p className="text-gray-600 text-sm leading-relaxed">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <Price
          serviceName="Skin Booster"
          serviceDescription="Hydrate and rejuvenate your skin for a radiant, youthful glow."
          services={servicesSkinBooster}
        />
        <br/>

<Footer/>
   </>
    
    
  );
};

export default SkinBooster;
