import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import WhatsappButton from '../Components/WhatsappButton';

const BlogDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { blog } = location.state || {};

  useEffect(() => {
    if (!blog) {
      navigate('/blog'); // Redirect to blog list if no blog data is found
    }
  }, [blog, navigate]);

  if (!blog) {
    return <div className="text-center py-20">No blog data found.</div>;
  }

  const relatedPosts = [
    {
        date: { day: "21", month: "Aug" },
        image: `${process.env.PUBLIC_URL}/assets/Blog/Dermal.jpg`,
        author: "Nazish",
        content: "Content",
        slug: "benefits-of-dermal-fillers",
        title: "What Are the Benefits of Dermal Fillers?",
        description1: "Dermal fillers offer a range of benefits, particularly for those seeking non-surgical solutions to enhance their appearance. One of the most appealing aspects of dermal fillers is the instant results they provide. Unlike other cosmetic treatments that may require a waiting period to see changes, fillers immediately smooth out wrinkles and add volume to areas such as the lips, cheeks, and under-eye hollows. This allows individuals to walk out of their appointment looking refreshed and rejuvenated.",
        description2: "Another significant advantage of dermal fillers is that they are non-surgical, making them a minimally invasive option with little to no downtime. This is ideal for those who want to improve their appearance without the risks and recovery time associated with surgery. After a filler treatment, most people can resume their daily activities almost immediately, which adds to the convenience.",
        description3: "Dermal fillers are also highly customizable. A skilled practitioner can tailor the treatment to meet the specific needs and desires of the individual, focusing on areas that need enhancement while ensuring a natural look. This personalization is key to achieving results that are harmonious with one’s facial features.",
        description4: "The effects of dermal fillers are long-lasting, with results that can persist for several months to over a year, depending on the type of filler used. While not permanent, this longevity makes fillers a cost-effective option for maintaining a youthful appearance over time.",
        description5: "Finally, dermal fillers can significantly boost confidence. By restoring volume and reducing the signs of aging, they can help individuals feel more comfortable and confident in their skin, enhancing both their appearance and self-esteem. These combined benefits make dermal fillers a popular and effective choice for those looking to rejuvenate their facial features without surgery.",

    },
    {
        date: { day: "18", month: "Aug" },
        image: `${process.env.PUBLIC_URL}/assets/Blog/meso.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Is Mesotherapy Right for Your Skin?",
        slug: "is-mesotherapy-right-for-your-skin",
        description1: "Mesotherapy is a popular cosmetic treatment that has gained attention for its ability to rejuvenate the skin and address a variety of concerns. It involves injecting a customized mixture of vitamins, enzymes, hormones, and other substances directly into the middle layer of the skin. The goal is to nourish and revitalize the skin, making it an appealing option for those looking to enhance their complexion. But is mesotherapy right for your skin?",
        description2: "One of the key benefits of mesotherapy is its versatility. The treatment can be tailored to address a wide range of skin concerns, including fine lines, wrinkles, hyperpigmentation, and sagging. It can also be used to improve the overall texture and tone of the skin. This makes it a good choice for individuals seeking a multi-targeted approach to skincare. However, the effectiveness of mesotherapy can vary depending on the specific issues you want to address and your skin type, so it’s important to consult with a qualified professional to determine if it’s the right treatment for you.",
        description3: "Mesotherapy is particularly appealing to those who want a natural approach to skin rejuvenation. The treatment often uses ingredients that are naturally found in the body, such as hyaluronic acid and vitamins, which can enhance the skin’s health from within. This aspect is attractive to individuals looking for treatments that work in harmony with their body’s natural processes. However, it's crucial to ensure that the provider uses high-quality, safe ingredients to avoid adverse reactions.",
        description4: "Finally, the results of mesotherapy are generally gradual and build over time. While some improvement may be seen after the first session, it typically takes several treatments to achieve the desired outcome. This gradual change allows for a more natural-looking enhancement, but it also requires patience and commitment. If you’re looking for immediate, dramatic results, you might need to consider combining mesotherapy with other treatments or exploring alternative options.",
        description5: "In conclusion, mesotherapy can be an excellent choice for those looking to rejuvenate their skin in a natural and gradual way. Its ability to target multiple concerns, combined with its customizable nature, makes it a versatile treatment. However, it’s essential to weigh the benefits against the potential discomfort and the need for multiple sessions. Consulting with a skincare professional can help you determine if mesotherapy aligns with your skincare goals and if it’s the right choice for your unique skin needs.",
        

    },
    {
        date: { day: "15", month: "Aug" },
        image:`${process.env.PUBLIC_URL}/assets/Blog/b12.jpeg`,
        author: "Nazish",
        content: "Content",
        title: "Why Consider Vitamin B12 Injections?",
        slug: "why-consider-vitamin-b12-injections",
        description1: "Vitamin B12 injections have become a popular choice for individuals seeking to enhance their health and well-being, particularly for those who may have difficulty obtaining adequate levels of this essential nutrient through diet alone. Vitamin B12 is crucial for many bodily functions, including nerve function, DNA production, and red blood cell formation. When someone is deficient in B12, it can lead to serious health issues such as anemia, fatigue, and neurological problems, making injections a vital option for those who need a direct and efficient way to increase their B12 levels.",
        description2: "One of the primary reasons people consider B12 injections is the significant boost in energy they can provide. Vitamin B12 plays a key role in converting food into energy, and those who are deficient often experience chronic fatigue and weakness. By delivering B12 directly into the bloodstream, injections bypass the digestive system, ensuring that the body receives and absorbs the nutrient more effectively, which can result in noticeable improvements in energy levels and overall vitality.",
        description3: "In addition to increasing energy, B12 injections are also linked to better cognitive function. A deficiency in B12 has been associated with memory loss, confusion, and other cognitive issues, particularly in older adults. Regular B12 injections can help support brain health, improve mental clarity, and potentially reduce the risk of age-related cognitive decline, making them an appealing option for those looking to maintain their cognitive abilities as they age.",
        description4: "Another important benefit of B12 injections is their positive impact on mood. Vitamin B12 is involved in the production of neurotransmitters that regulate mood and stress levels. Some individuals find that B12 injections help alleviate symptoms of depression and anxiety, contributing to a greater sense of emotional well-being. This mood-stabilizing effect makes B12 injections an attractive option for those struggling with mood disorders or high levels of stress.",
        description5: "Lastly, certain groups of people, such as vegetarians, vegans, and the elderly, are at a higher risk of B12 deficiency due to dietary restrictions or decreased absorption with age. For these individuals, B12 injections offer a reliable and effective way to maintain optimal levels of this vital nutrient, preventing deficiency-related health problems and ensuring they can enjoy better overall health. In summary, B12 injections provide a range of benefits, from boosting energy and cognitive function to improving mood and supporting those at risk of deficiency.",
       
    },
    {
        date: { day: "12", month: "Aug" },
        image: `${process.env.PUBLIC_URL}/assets/Blog/micro.jpg`,
        author: "Nazish",
        content: "Content",
        title: "How Does Microneedling Improve Your Skin?",
        slug: "how-does-microneedling-improve-your-skin",
        description1: "Microneedling has become an increasingly popular skincare treatment, particularly for those seeking to improve their skin’s texture and overall appearance. The procedure involves using a device with fine needles to create tiny, controlled punctures in the skin’s surface. While this may sound intense, these micro-injuries stimulate the skin’s natural healing process, leading to the production of collagen and elastin, which are essential for maintaining firm, smooth, and youthful-looking skin.",
        description2: "One of the primary reasons people consider microneedling is its ability to reduce the appearance of fine lines and wrinkles. By boosting collagen production, microneedling helps plump up the skin, smoothing out lines and giving the face a more youthful appearance. This makes it an attractive option for those looking to combat early signs of aging and maintain a smoother complexion over time.",
        description3: "In addition to its anti-aging benefits, microneedling is highly effective in treating various types of scars, particularly acne scars. The micro-injuries created during the procedure help break down old scar tissue and promote the growth of new, healthy skin cells. Over time, this can lead to a noticeable reduction in the visibility of scars, making the skin appear more even and clear.",
        description4: "Microneedling also improves the overall texture and tone of the skin by minimizing pores and reducing the appearance of hyperpigmentation. The treatment encourages the turnover of new skin cells, resulting in a smoother, more radiant complexion. This improvement in skin texture and tone makes microneedling a versatile treatment for a wide range of skin concerns.",
        description5: "Lastly, microneedling enhances the effectiveness of topical skincare products. The tiny channels created during the procedure allow serums and other treatments to penetrate deeper into the skin, where they can be more effective. This means that your skincare routine can deliver better results after microneedling, contributing to greater improvements in your skin’s appearance and health overall."
    },
    {
        date: { day: "09", month: "Aug" },
        image: `${process.env.PUBLIC_URL}/assets/Blog/hair.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Can Hair Restoration Treatments Really Work?",
        slug: "can-hair-restoration-treatments-work",
        description1: "Hair restoration treatments have gained significant attention for their potential to address hair loss and thinning, offering hope to those struggling with these issues. These treatments range from topical solutions and oral medications to more advanced procedures like hair transplants and platelet-rich plasma (PRP) therapy. Each method works differently, targeting various aspects of hair growth and follicle health, but the question remains—do they really work?",
        description2: "One of the most well-known treatments is hair transplant surgery, which involves moving hair follicles from one part of the body to the thinning or balding areas. This procedure has been proven to be effective for many individuals, especially those with pattern baldness. Results can be quite natural-looking and long-lasting, making it a popular choice for those looking for a permanent solution to hair loss.",
        description3: "Topical treatments like minoxidil (commonly known as Rogaine) are another widely used option. Applied directly to the scalp, minoxidil works by stimulating hair follicles and prolonging the growth phase of hair. While it doesn’t work for everyone, many users report noticeable improvements in hair density and reduced shedding, particularly when used consistently over several months.",
        description4: "Platelet-rich plasma (PRP) therapy is a newer approach that involves injecting a concentration of the patient’s own platelets into the scalp. The growth factors in the platelets are believed to stimulate hair follicles and promote new hair growth. Though still relatively new, early studies and anecdotal evidence suggest that PRP can be effective, especially when combined with other treatments.",
        description5: "While hair restoration treatments can be effective, it’s important to have realistic expectations and understand that results can vary based on factors like the cause of hair loss, the individual’s health, and the specific treatment used. Consulting with a specialist is crucial to determine the most appropriate treatment plan and to assess whether hair restoration is a viable option for your specific situation."

    },
    {
        date: { day: "06", month: "Aug" },
        image:  `${process.env.PUBLIC_URL}/assets/Blog/hydra.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Which HydraFacial Is Best for You?",
        slug: "which-hydrafacial-is-best-for-you",
        description1: "HydraFacial treatments have become a go-to solution for many looking to rejuvenate their skin, offering a deep cleanse, exfoliation, extraction, and hydration all in one session. The versatility of HydraFacials means that they can be customized to address a variety of skin concerns, making them suitable for almost anyone. However, with several different types of HydraFacials available, it can be challenging to determine which one is best for your unique skin needs.",
        description2: "The Signature HydraFacial is the most basic version, providing a thorough cleanse and exfoliation, followed by gentle extractions and hydration. This treatment is ideal for those looking for a quick refresh and a glowing complexion. It’s a great starting point if you’re new to HydraFacials or if your skin doesn’t require any targeted treatment for specific concerns.",
        description3: "For those dealing with more specific skin issues like hyperpigmentation, fine lines, or acne, the Deluxe HydraFacial might be a better option. This version includes all the steps of the Signature treatment but adds specialized boosters tailored to your skin’s needs. These boosters can help target concerns more effectively, making the Deluxe HydraFacial a popular choice for those looking to address particular skin issues.",
        description4: "The Platinum HydraFacial is the most comprehensive option, combining the benefits of the Signature and Deluxe treatments with added lymphatic drainage to detoxify the skin. This version is perfect for individuals seeking an intensive treatment that not only addresses skin concerns but also promotes overall skin health. The Platinum HydraFacial is ideal for those looking for the ultimate in skincare luxury and effectiveness.",
        description5: "Choosing the right HydraFacial depends on your skin type, concerns, and goals. Consulting with a skincare professional can help you determine which HydraFacial will provide the best results for your specific needs. Whether you’re looking for a quick refresh or a more targeted approach, there’s a HydraFacial option that’s perfectly suited to help you achieve your skincare goals."
    },
    {
        date: { day: "03", month: "Aug" },
        image:  `${process.env.PUBLIC_URL}/assets/Blog/cos.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Is the Cosmelan Peel the Solution to Pigmentation?",
        slug: "is-cosmelan-peel-solution-to-pigmentation",
        description1: "The Cosmelan Peel has gained recognition as a powerful treatment for addressing pigmentation issues, offering hope to those struggling with dark spots, melasma, and other forms of hyperpigmentation. This chemical peel works by inhibiting the enzyme tyrosinase, which plays a key role in the production of melanin, the pigment responsible for skin color. By reducing melanin production, the Cosmelan Peel helps lighten existing pigmentation and prevent new spots from forming.",
        description2: "One of the primary reasons people turn to the Cosmelan Peel is its effectiveness in treating stubborn pigmentation, especially in cases where other treatments have failed. Unlike some treatments that only target surface pigmentation, the Cosmelan Peel penetrates deeper into the skin, providing more comprehensive results. This makes it an attractive option for individuals with persistent pigmentation issues that haven’t responded well to over-the-counter products or other procedures.",
        description3: "In addition to its powerful depigmenting effects, the Cosmelan Peel also helps improve overall skin tone and texture. The peel not only lightens dark spots but also promotes a more even complexion by addressing sun damage and other skin imperfections. Many patients report a significant improvement in their skin’s brightness and clarity after undergoing the Cosmelan Peel, making it a popular choice for those seeking a more radiant appearance.",
        description4: "However, it’s important to note that the Cosmelan Peel requires a commitment to a strict post-treatment skincare regimen to maintain results and minimize side effects. This includes avoiding sun exposure, using high SPF sunscreen, and applying specific post-peel products to support the skin’s recovery. For those willing to follow these guidelines, the Cosmelan Peel can be a highly effective solution for managing pigmentation.",
        description5: "Ultimately, whether the Cosmelan Peel is the right solution for your pigmentation depends on your specific skin type, the severity of your pigmentation, and your ability to adhere to the post-treatment care. Consulting with a dermatologist or skincare professional is crucial to determine if the Cosmelan Peel is the best option for you and to ensure you achieve the desired results safely and effectively."

    },
    {
        date: { day: "30", month: "Jul" },
        image:  `${process.env.PUBLIC_URL}/assets/Blog/rf.jpg`,
        author: "Nazish",
        content: "Content",
        title: "What Makes RF Fractional So Effective?",
        slug: "what-makes-rf-fractional-effective",
        description1: "RF Fractional, or Radiofrequency Fractional, treatments have become a popular choice for those seeking to rejuvenate their skin without invasive surgery. This technology combines radiofrequency energy with microneedling to deliver heat deep into the skin’s layers, stimulating collagen production and tightening the skin. The combination of these two powerful techniques makes RF Fractional an effective treatment for a variety of skin concerns, from wrinkles and sagging to acne scars and uneven texture.",
        description2: "One of the key factors that make RF Fractional so effective is its ability to target both the surface and deeper layers of the skin simultaneously. The microneedles create tiny channels in the skin, allowing the radiofrequency energy to penetrate deeper than other treatments. This dual action promotes a more comprehensive remodeling of the skin’s structure, leading to firmer, smoother, and more youthful-looking skin.",
        description3: "RF Fractional treatments are also highly customizable, which adds to their effectiveness. The intensity of the treatment can be adjusted based on the patient’s skin type, concerns, and desired outcomes. This customization allows for targeted treatment of specific areas, making it possible to address fine lines around the eyes, deep wrinkles, or acne scars with precision, leading to better and more personalized results.",
        description4: "Another significant advantage of RF Fractional treatments is the minimal downtime compared to more invasive procedures. While the treatment does involve some discomfort and minor redness, most patients can return to their normal activities within a day or two. This makes it an appealing option for those looking for significant skin improvement without the long recovery period associated with surgery.",
        description5: "Lastly, the results of RF Fractional treatments are long-lasting, especially when combined with a good skincare routine. As collagen production increases and the skin continues to heal and remodel itself over time, patients often see continued improvement in their skin’s texture, tone, and firmness for months after the treatment. This long-term benefit, combined with the treatment’s versatility and minimal downtime, makes RF Fractional a highly effective solution for skin rejuvenation."
    },
    {
        date: { day: "27", month: "Jul" },
        image:  `${process.env.PUBLIC_URL}/assets/Blog/boster.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Why Choose a Skin Booster?",
        slug: "why-choose-a-skin-booster",
        description1: "Skin boosters have become a popular treatment for those looking to achieve a naturally radiant complexion and improve skin hydration. Unlike traditional fillers that add volume, skin boosters work by deeply hydrating the skin and improving its overall texture and elasticity. This makes them an excellent choice for individuals who want a subtle yet noticeable enhancement to their skin’s appearance without the need for more invasive procedures.",
        description2: "One of the primary benefits of choosing a skin booster is its ability to deliver intense hydration directly to the deeper layers of the skin. Skin boosters typically contain hyaluronic acid, a substance naturally found in the body that can hold up to 1,000 times its weight in water. By injecting hyaluronic acid into the skin, skin boosters help to restore moisture levels, leading to a plumper, smoother, and more hydrated complexion.",
        description3: "In addition to boosting hydration, skin boosters can also improve the skin’s texture and elasticity. Over time, factors like aging, sun exposure, and environmental stressors can lead to a loss of skin elasticity and the development of fine lines and wrinkles. Skin boosters stimulate collagen production and enhance the skin’s ability to retain moisture, resulting in a firmer, more youthful appearance.",
        description4: "Another reason to consider skin boosters is their versatility. They can be used on various areas of the face, neck, and even the hands to address specific skin concerns. Whether you’re dealing with dull, tired-looking skin, fine lines, or loss of elasticity, skin boosters can be tailored to your unique needs, making them a highly customizable treatment option.",
        description5: "Finally, skin boosters are a minimally invasive treatment with little to no downtime. Most sessions are quick and relatively painless, with patients able to return to their normal activities almost immediately. The results of skin boosters are gradual and natural-looking, making them an ideal choice for those who want to enhance their skin’s appearance subtly and effectively. For anyone seeking long-lasting hydration and a healthy glow, skin boosters offer a compelling solution."

    },
    {
        date: { day: "24", month: "Jul" },
        image: `${process.env.PUBLIC_URL}/assets/Blog/boster.jpg`,
        author: "Nazish",
        content: "Content",
        title: "Should You Try Anti-Wrinkle Injections?",
        slug: "should-you-try-anti-wrinkle-injections",
        description1: "Anti-wrinkle injections, often referred to as Botox, have become one of the most popular cosmetic treatments for reducing the appearance of fine lines and wrinkles. These injections work by temporarily relaxing the facial muscles responsible for creating wrinkles, particularly in areas like the forehead, around the eyes, and between the eyebrows. This relaxation leads to a smoother, more youthful appearance, making anti-wrinkle injections an attractive option for those looking to combat the signs of aging.",
        description2: "One of the main reasons people consider anti-wrinkle injections is their effectiveness in delivering quick and noticeable results. The procedure typically takes just a few minutes, with minimal discomfort, and the results can start to appear within a few days. For those who want a fast solution to fine lines and wrinkles without the need for invasive surgery, anti-wrinkle injections offer a convenient and effective option.",
        description3: "In addition to their immediate effects, anti-wrinkle injections can also serve as a preventative measure against the formation of deeper wrinkles. By relaxing the muscles that cause expression lines, these injections can help prevent the lines from becoming more pronounced over time. This makes them a valuable tool for individuals who want to maintain a youthful appearance and slow the aging process.",
        description4: "Another advantage of anti-wrinkle injections is the minimal downtime associated with the treatment. Most patients can resume their normal activities right after the procedure, with little to no recovery time needed. This makes it easy to fit the treatment into a busy schedule, and there are usually no significant side effects beyond mild redness or swelling at the injection site.",
        description5: "Ultimately, whether anti-wrinkle injections are right for you depends on your specific skincare goals and preferences. If you’re looking for a non-invasive way to reduce the appearance of wrinkles and maintain a youthful look, these injections could be a great option. However, it’s important to consult with a qualified practitioner to ensure the treatment is tailored to your needs and to discuss any potential risks or concerns you may have."

    }
];


  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <WhatsappButton/>
      <main className="flex-grow">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10 grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-8 bg-white shadow-lg rounded-lg overflow-hidden p-6">
            <button
              className="mb-4 px-4 py-2 bg-[#c16240] text-white"
              onClick={() => navigate(-1)}
            >
              &larr; Back
            </button>
            {blog.image && (
              <img 
                src={blog.image} 
                alt={blog.title} 
                className="w-full h-72 object-cover rounded-lg mb-6"
              />
            )}
            <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
            <div className="flex items-center text-sm text-gray-600 mb-6">
              <span className="mr-2">{blog.author}</span>
              <span className="mx-2">|</span>
              {blog.date && (
                <span>{`${blog.date.day} ${blog.date.month}`}</span>
              )}
            </div>
           
            <div className="text-gray-800 mt-10 space-y-4">
              <h2 className="text-2xl font-semibold mb-4">Description</h2>
              <p>{blog.description1}</p> 
              <p>{blog.description2}</p> 
              <p>{blog.description3}</p> 
              <p>{blog.description4}</p> 
              <p>{blog.description5}</p> 
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-4 space-y-6">
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-2xl font-semibold mb-4">Related Posts</h3>
              <ul className="space-y-6">
                {relatedPosts.map((post, index) => (
                  <li key={index} className="flex items-center space-x-4">
                    <img 
                      src={post.image} 
                      alt={post.title} 
                      className="w-20 h-20 object-cover rounded-lg shadow-sm"
                    />
                    <Link 
                      to="/blogdetail"
                      state={{ blog: post }}  // Passing the blog data via state
                      className="text-left text-[#c16240] hover:underline text-lg"
                    >
                      {post.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogDetail;
