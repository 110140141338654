import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';

const VIPVisit = () => {
  return (
    <div className="container mx-auto px-4 py-8 flex flex-col lg:flex-row items-center lg:items-start">
      {/* Image Section */}
      <div className="lg:w-1/2 flex justify-center lg:justify-start mb-8 lg:mb-0">
        <img
          src={`${process.env.PUBLIC_URL}/assets/vip.png`}
          alt="VIP Visit"
          className="w-full h-auto max-w-lg rounded-lg shadow-lg"
        />
      </div>

      {/* Text Section */}
      <div className="lg:w-1/2 lg:pl-8">
        <h1 className="text-4xl font-bold mb-4">VIP Visit</h1>
        <p className="text-gray-700 mb-4">
          We provide a VIP visit that involves a comprehensive consultation on
          the aesthetic procedure, lasting approximately one hour, and may be
          booked at a location convenient to you.
        </p>
        <p className="text-gray-700 mb-4">
          A VIP appointment costs £500.
        </p>
        <p className="text-gray-700">
          Cancellations made within 24 hours will not get a return of the
          deposit.
        </p>
        <br/>
        <a
      href="tel:+441234567890"
      className="inline-flex items-center bg-[#c16240] text-white py-2 px-3 hover:bg-[#b3952a]"
    >
      <FaPhoneAlt className="mr-2" />
      Contact Us 
    </a>
      </div>
    </div>
  );
};

export default VIPVisit;
